import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Strong, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Pizzeria Pizza Przystan
			</title>
			<meta name={"description"} content={"Zanurz się w naszym świecie aromatycznej pizzy pieczonej do perfekcji i pozwól swoim kubkom smakowym wyruszyć w kulinarną podróż niezrównaną pod względem smaku i świeżości."} />
			<meta property={"og:title"} content={"Pizzeria Pizza Przystan"} />
			<meta property={"og:description"} content={"Zanurz się w naszym świecie aromatycznej pizzy pieczonej do perfekcji i pozwól swoim kubkom smakowym wyruszyć w kulinarną podróż niezrównaną pod względem smaku i świeżości."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3">
				Warszawska 17, 31-155{" "}
				<br />
				Kraków, Poland
			</Override>
			<Override slot="link2">
				+48573242188
			</Override>
		</Components.Header>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-16">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					padding="0px 0px 50% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						src="https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/pizzeria-sterzing.jpg?v=2024-06-13T13:23:28.667Z"
						position="absolute"
						width="100%"
						bottom="0px"
						right={0}
						min-height="100%"
						object-fit="cover"
						display="block"
						top="auto"
						left={0}
						srcSet="https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/pizzeria-sterzing.jpg?v=2024-06-13T13%3A23%3A28.667Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/pizzeria-sterzing.jpg?v=2024-06-13T13%3A23%3A28.667Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/pizzeria-sterzing.jpg?v=2024-06-13T13%3A23%3A28.667Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/pizzeria-sterzing.jpg?v=2024-06-13T13%3A23%3A28.667Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/pizzeria-sterzing.jpg?v=2024-06-13T13%3A23%3A28.667Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/pizzeria-sterzing.jpg?v=2024-06-13T13%3A23%3A28.667Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/pizzeria-sterzing.jpg?v=2024-06-13T13%3A23%3A28.667Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				justify-content="space-between"
				width="58%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				lg-justify-content="center"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box display="flex" flex-direction="column" md-width="100%">
					<Text
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						md-text-align="left"
					>
						Wybór Pizza Przystan oznacza wybór jakości, której możesz spróbować. Staramy się tworzyć coś więcej niż tylko jedzenie, ale doświadczenia, które łączą ludzi.
					</Text>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
				width="40%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="space-between"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-padding="0px 16px 16px 16px"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						<Strong>
							Dlatego wielu wybiera nas:
							<br />
						</Strong>
						<br />
						{"\n\n"}Gwarantowana świeżość: każdy składnik jest wybierany ręcznie, aby uzyskać najświeższy i najsmaczniejszy efekt.
Nacisk na społeczność: Wierzymy we wzmacnianie naszej społeczności poprzez wspieranie lokalnych rolników i przedsiębiorstw. Innowacyjne menu:
Stale zmieniające się menu sprawia, że ​​posiłki stają się coraz bardziej ekscytujące z każdą wizytą.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z) center/cover repeat scroll padding-box" sm-padding="80px 0 80px 0" quarkly-title="Product-7">
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="400px"
					background="rgba(255, 255, 255, 0.9)"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
					border-radius="30px"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" text-align="center">
						Gdzie tradycja spotyka się ze smakiem w każdym kęsie
					</Text>
					<Text margin="0px 0px 35px 0px" text-align="center" font="normal 400 18px/1.5 --fontFamily-sansHelvetica">
						Zanurz się w naszym świecie aromatycznej pizzy pieczonej do perfekcji i pozwól swoim kubkom smakowym wyruszyć w kulinarną podróż niezrównaną pod względem smaku i świeżości.
					</Text>
					<Button background="--color-darkL1" padding="12px 30px 12px 30px" border-radius="25px">
						Menu
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				align-self="center"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					O nas
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					W pizzerii Pizza Przystan jesteśmy dumni, że używamy wyłącznie najlepszych, lokalnych składników, aby wspierać naszą społeczność i zapewnić maksymalną świeżość. Nasi doświadczeni szefowie kuchni łączą sprawdzone przepisy z innowacyjnymi technikami, aby stworzyć pizzę, która nie tylko zaspokoi Twoje pragnienia, ale także rozpali Twoje kubki smakowe. Każda pizza to arcydzieło, tworzone z dbałością i pasją, odzwierciedlające nasze przywiązanie do jakości i smaku.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/48727-Mikes-homemade-pizza-DDMFS-beauty-4x3-BG-2974-a7a9842c14e34ca699f3b7d7143256cf.jpg?v=2024-06-13T13:23:28.669Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/48727-Mikes-homemade-pizza-DDMFS-beauty-4x3-BG-2974-a7a9842c14e34ca699f3b7d7143256cf.jpg?v=2024-06-13T13%3A23%3A28.669Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/48727-Mikes-homemade-pizza-DDMFS-beauty-4x3-BG-2974-a7a9842c14e34ca699f3b7d7143256cf.jpg?v=2024-06-13T13%3A23%3A28.669Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/48727-Mikes-homemade-pizza-DDMFS-beauty-4x3-BG-2974-a7a9842c14e34ca699f3b7d7143256cf.jpg?v=2024-06-13T13%3A23%3A28.669Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/48727-Mikes-homemade-pizza-DDMFS-beauty-4x3-BG-2974-a7a9842c14e34ca699f3b7d7143256cf.jpg?v=2024-06-13T13%3A23%3A28.669Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/48727-Mikes-homemade-pizza-DDMFS-beauty-4x3-BG-2974-a7a9842c14e34ca699f3b7d7143256cf.jpg?v=2024-06-13T13%3A23%3A28.669Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/48727-Mikes-homemade-pizza-DDMFS-beauty-4x3-BG-2974-a7a9842c14e34ca699f3b7d7143256cf.jpg?v=2024-06-13T13%3A23%3A28.669Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666ac29b3f24780021f7d54f/images/48727-Mikes-homemade-pizza-DDMFS-beauty-4x3-BG-2974-a7a9842c14e34ca699f3b7d7143256cf.jpg?v=2024-06-13T13%3A23%3A28.669Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text">
				Zapraszamy już dziś na kawałek!
			</Override>
			<Override slot="text1">
				Chcieć więcej? Już dziś odwiedź pizzerię Pizza Przystan i zasmakuj tradycji. Nasza pizza czeka, aby wypełnić Wasze chwile radością i smakiem. Pamiętaj, że każdy posiłek to okazja do wspomnień, więc spraw, by Twój był pyszny w Pizza Przystan!
			</Override>
			<Override slot="image" src="https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/pizza-recipes-646258e7276df.jpeg?v=2024-06-13T13:23:28.664Z" />
			<Override slot="box2" align-self="center" />
		</Components.Cta>
		<Components.Footer>
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6666e55e1d712a002334ca50"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});